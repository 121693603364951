import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import useBlogData from '../static_queries/useBlogData'
import blogTemplateStyles from "../styles/templates/blog.module.scss"
//this component handles the blur img & fade-ins
import Img from 'gatsby-image'

const renderSubsection = subsection => {
  console.log({ subsection })
  return {
    ['intro-title']: sub => <h1 className={blogTemplateStyles.title}>{sub.label}</h1>,
    ['subtitle']: sub => <h2 className={blogTemplateStyles.subtitle}>{sub.label}</h2>,
    ['paragraph']: sub => <p className={blogTemplateStyles.paragraph}>{sub.content}</p>,
    ['full-image']: sub => <div className={blogTemplateStyles.image}><img alt="image" src={`https://abaio.s3-ap-southeast-2.amazonaws.com/ahux${sub.image}`} /></div>,
  }[subsection.template](subsection)
}

export default function Blog(props) {
  const data = props.data.markdownRemark
  const allBlogData = useBlogData()
  const nextSlug = getNextSlug(data.fields.slug)

  function getNextSlug(slug) {
    const allSlugs = allBlogData.map(blog => {
      return blog.node.fields.slug
    })
    const nextSlug = allSlugs[allSlugs.indexOf(slug) + 1]
    if(nextSlug !== undefined && nextSlug !== '') {
      return nextSlug
    } else {
      return allSlugs[0]
    }
  }

  // console.log({ data })
  return (
    <div className={blogTemplateStyles.blogPage}>
      <div className={blogTemplateStyles.blogContainer}>
        {data.frontmatter.layout.map(section => (
          <div className={section.isFullWidth ? blogTemplateStyles.fullWidth : blogTemplateStyles.halfWidth}>
            {section.contents.map(renderSubsection)}
          </div>
        ))}
      </div>
    </div>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        author
        date(formatString: "MMMM Do, YYYY")
        layout {
          template 
            contents {
              label
              template 
              content
              image
            }
          isFullWidth
        }
      }
      html
    }
  }
`
